import {
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Chip,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import { Authenticator } from "@aws-amplify/ui-react";
import Markdown from "react-markdown";
import "@aws-amplify/ui-react/styles.css";
import "../App.css";
import awsExports from "../aws-exports";
import ImageBannerWithText from "../components/ImageBannerWithText";
import copyIcon from "../assets/copyIcon.png";
import { getSessionJwt } from "../util/utilities";
import { useMediaQuery } from "@mui/material";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import { ENDPOINTS } from "../util/constants";

Amplify.configure(awsExports);

const SuggestedPrompts = ({
  setInput,
  setMessages,
  sendMessage,
  hidePrompts,
  prompts = [], // Default to an empty array
}) => {
  const handlePromptClick = (prompt) => {
    // Set the input field value
    setInput(prompt);

    // Trigger the submit button click
    setTimeout(() => {
      const form = document.querySelector("form.chat-input");
      const submitButton = form.querySelector('button[type="submit"]');

      if (submitButton) {
        submitButton.click();
      }

      hidePrompts(); // Hide prompts after one is clicked
    }, 100); // Delay to simulate user interaction
  };

  return prompts.length > 0 ? (
    <Box sx={{ textAlign: "center", marginY: 2 }}>
      <Typography variant="h6" gutterBottom>
        Suggested Prompts
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {prompts.map((prompt, index) => (
          <Grid item key={index}>
            <Button
              variant="outlined"
              onClick={() => handlePromptClick(prompt)}
              sx={{
                minWidth: 200,
                padding: "15px 20px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
                fontSize: "14px",
                color: "#333",
                backgroundColor: "#fff",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              {prompt}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  ) : null; // Return null if no prompts to display
};

export default function GptLayout(props) {
  const {
    headerText,
    subheaderText,
    getChat,
    getChats,
    sendMessage,
    isLoading,
    setIsLoading,
    input,
    setInput,
    responseText,
    setResponseText,
    messages,
    setMessages,
    waitingAfterDone,
    setWaitingAfterDone,
    isLoadingPastChats,
    pastChats,
    prompts, // Pass prompts from parent component or define locally
  } = props;

  const [subscriptionStatus, setSubscriptionStatus] = useState("PENDING");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [authActivity, setAuthActivity] = useState(0);
  const [pastChatsWindow, setPastChatsWindow] = useState(false);
  const [showPrompts, setShowPrompts] = useState(true);
  const [files, setFiles] = useState([]);

  const inputRef = useRef(null);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    console.log(`Browser doesn't support speech recognition.`);
  }

  useEffect(() => {
    if (responseText && waitingAfterDone && isLoading) {
      const apiMessage = {
        text: responseText,
        sender: "gpt",
      };
      setMessages((prevMessages) => [...prevMessages, apiMessage]);
      setResponseText("");
      setWaitingAfterDone(false);
      setIsLoading(false);
      setShowPrompts(false);
    }
  }, [waitingAfterDone]);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const createCheckoutAndRedirect = async (e) => {
    e.preventDefault();
    try {
      const jwtToken = await getSessionJwt();

      const response = await fetch(
        `${ENDPOINTS.EC2_ENDPOINT}/subscription/create-checkout`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      const data = await response.json();
      const checkoutURL = data.results.checkoutURL;
      if (checkoutURL) {
        window.location.href = checkoutURL;
      }
    } catch (error) {
      console.error("Error creating checkout. Message:", error);
    }
  };

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const jwtToken = await getSessionJwt();
      if (!jwtToken) {
        setSubscriptionStatus("Refresh page!");
        return;
      }
      const response = await fetch(
        `${ENDPOINTS.EC2_ENDPOINT}/subscription/get-subscription-status`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      const data = await response.json();
      if (!data.results) {
        console.error(
          "Error fetching subscription status. Message:",
          data.message
        );
        setSubscriptionStatus("Error. Refresh page!");
        return;
      }
      setSubscriptionStatus(data.results.subscriptionStatus);
      inputRef.current.focus();
    };
    fetchSubscriptionStatus();
  }, [authActivity]);

  const hubListenerCancel = Hub.listen("auth", (data) => {
    setAuthActivity((prev) => prev + 1);
    if (data.payload.event === "signedIn") {
      hubListenerCancel();
    }
  });

  useEffect(() => {
    if (transcript.length > 0 && !listening) {
      if (input.length > 0) {
        setInput((prev) => prev + " " + transcript);
      } else {
        setInput((prev) => prev + transcript);
      }
      inputRef.current.focus();
      setShowPrompts(false);
    }
  }, [listening]);

  const endOfMessagesRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the messages when a new message is added
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleFocus = () => {
    if (input.length > 0) {
      setShowPrompts(false);
    }
  };

  const handleFileChange = (event) => {
    setFiles([...files, ...Array.from(event.target.files)]);
  };

  const handleRemoveFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleSend = (e) => {
    if (input?.trim()?.length > 0) {
      sendMessage({ event: e, text: input, files });
      setFiles([]);
    }
  };

  return (
    <>
      <ImageBannerWithText
        header={headerText}
        subHeader={
          subheaderText ??
          "An AI digital assistant for Ophthalmic Care professionals."
        }
        imgLink="https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/eye-with-binary-data.jpeg"
        imgAltText="EyeGpt"
        height={isMobile ? 95 : 150}
      />
      <Authenticator>
        {({ signOut, user }) => (
          <main>
            <Modal open={pastChatsWindow}>
              <div
                sx={{
                  overflowY: "auto",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: isMobile ? "35vh" : "50vh",
                    height: "80vh",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    marginY: "20px",
                    overflowY: "auto",
                  }}
                >
                  <Typography
                    variant="h7"
                    gutterBottom
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {`Previous Chats (click to go to)`}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setPastChatsWindow(!pastChatsWindow);
                      }}
                    >
                      {"Close"}
                    </Button>
                  </Box>
                  {isLoadingPastChats && (
                    <Typography
                      variant="h7"
                      gutterBottom
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {`Loading past chats...`}
                    </Typography>
                  )}
                  {pastChats.map((chat, index) => (
                    <div key={index}>
                      <Button
                        onClick={(e) => {
                          getChat(e, chat.chatID || chat.threadID);
                          setPastChatsWindow(!pastChatsWindow);
                        }}
                      >
                        <Typography variant="h7" gutterBottom>
                          <b>{chat.name}</b>
                        </Typography>
                      </Button>
                    </div>
                  ))}
                </Box>
              </div>
            </Modal>
            <Grid container alignItems="center">
              <Grid
                item
                xs={12}
                sm={1.5}
                sx={{ textAlign: "center", bgcolor: "#808080" }}
              >
                <div className={!isMobile && "gpt-sidebar-container"}>
                  <br />
                  <Typography variant="h7" gutterBottom>
                    Welcome back, <b>{user.username}</b>
                  </Typography>
                  {!isMobile && <br />}
                  <br />
                  <Typography variant="h7" gutterBottom>
                    <b>
                      {subscriptionStatus === true
                        ? "ACTIVE"
                        : subscriptionStatus === false
                        ? "INACTIVE"
                        : subscriptionStatus}
                    </b>
                  </Typography>
                  {!isMobile && <br />}
                  <br />
                  <Button
                    sx={{
                      bgcolor: "#103444",
                      color: "white",
                      fontWeight: "bold",
                      marginTop: "20px",
                    }}
                    onClick={(e) => {
                      getChats(e);
                      setPastChatsWindow(!pastChatsWindow);
                    }}
                  >
                    View Past Chats
                  </Button>
                  {subscriptionStatus === true && (
                    <Button
                      href="https://billing.stripe.com/p/login/3csaHQcOy9G2g6c6oo"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        bgcolor: "#103444",
                        color: "white",
                        fontWeight: "bold",
                        marginTop: "20px",
                      }}
                    >
                      Manage Subscription
                    </Button>
                  )}
                  {subscriptionStatus === false && (
                    <Button
                      sx={{
                        bgcolor: "#103444",
                        color: "white",
                        fontWeight: "bold",
                        marginTop: "20px",
                      }}
                      onClick={createCheckoutAndRedirect}
                    >
                      Get a Subscription
                    </Button>
                  )}
                  <Button
                    sx={{
                      bgcolor: "#6aa0a3",
                      color: "white",
                      fontWeight: "bold",
                      marginTop: "20px",
                    }}
                    onClick={signOut}
                  >
                    Sign Out
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={10.5}>
                <div
                  className={
                    isMobile ? "app-container-mobile" : "app-container"
                  }
                >
                  <div className="chat-container">
                    {showPrompts && prompts && prompts.length > 0 && (
                      <SuggestedPrompts
                        setInput={setInput}
                        setMessages={setMessages}
                        sendMessage={sendMessage}
                        hidePrompts={() => setShowPrompts(false)}
                        prompts={prompts}
                      />
                    )}
                    <div className="chat-messages">
                      {messages.map((message, index) => (
                        <div
                          key={index}
                          className={`message ${
                            message.sender === "user"
                              ? "user-message"
                              : "api-message"
                          }`}
                        >
                          <Markdown className="message-content">
                            {message.text}
                          </Markdown>
                          <button
                            className="copy-icon"
                            onClick={() => copyToClipboard(message.text)}
                          >
                            <img src={copyIcon} alt="Copy" />
                          </button>
                        </div>
                      ))}
                      {isLoading && (
                        <div
                          key={"loadingDots"}
                          className={`message ${"api-message"}`}
                        >
                          {responseText + ` ...`}
                        </div>
                      )}
                      {/* This is the element to scroll into view */}
                      <div ref={endOfMessagesRef} />
                    </div>
                    <form class="chat-input" onSubmit={handleSend}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "10px",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ display: "flex", width: "100%" }}>
                          <TextField
                            variant="outlined"
                            placeholder="Type a message..."
                            fullWidth
                            value={
                              input +
                              (listening
                                ? input?.length > 0
                                  ? " " + transcript
                                  : transcript
                                : "")
                            }
                            input={input}
                            disabled={subscriptionStatus !== true}
                            onChange={(e) => setInput(e.target.value)}
                            inputRef={inputRef}
                            sx={{ bgcolor: "white" }}
                            onFocus={handleFocus}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <input
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id="file-upload"
                                    type="file"
                                    multiple
                                    onChange={handleFileChange}
                                  />
                                  <label htmlFor="file-upload">
                                    <IconButton component="span">
                                      <AttachFileIcon />
                                    </IconButton>
                                  </label>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Button
                            disabled={subscriptionStatus !== true}
                            onClick={SpeechRecognition.startListening}
                            style={{ background: listening && "#870505" }}
                          >
                            {listening ? <MicIcon /> : <MicOffIcon />}
                          </Button>
                          <IconButton
                            type="submit"
                            color="primary"
                            disabled={input?.trim()?.length === 0}
                          >
                            <SendIcon />
                          </IconButton>
                        </div>
                        {files.length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              marginTop: "10px",
                            }}
                          >
                            {files.map((file, index) => (
                              <Chip
                                key={index}
                                label={file.name}
                                onDelete={() => handleRemoveFile(index)}
                                style={{ margin: "5px" }}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </Grid>
            </Grid>
          </main>
        )}
      </Authenticator>
    </>
  );
}
